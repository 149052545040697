body {
  margin: 0;
  font-family: "NotoSansKR", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "NotoSansKR";
  src: local("NotoSansKR-Bold"),
    url("https://cdn.jsdelivr.net/gh/redwit-dev/goono-web-storage@dev/NotoSans-Bold.woff2")
      format("woff2");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "NotoSansKR";
  src: local("NotoSansKR-Medium"),
    url("https://cdn.jsdelivr.net/gh/redwit-dev/goono-web-storage@dev/NotoSans-Medium.woff2")
      format("woff2");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "NotoSansKR";
  src: local("NotoSansKR-Regular"),
    url("https://cdn.jsdelivr.net/gh/redwit-dev/goono-web-storage@dev/NotoSans-Regular.woff2")
      format("woff2");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "NotoSansKR";
  src: local("NotoSansKR-Light"),
    url("https://cdn.jsdelivr.net/gh/redwit-dev/goono-web-storage@dev/NotoSans-Light.woff2")
      format("woff2");
  font-weight: 400;
  font-display: swap;
}
